@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  position: relative;
  padding-left: 48px;
  background: #FFFFFF;
  border: 1px solid rgb(209 213 219 / 1);
  border-radius: 6px !important;
  width: 100% !important;
  outline: none;
  height: 38px! important;
  font-size: 12px !important;
  letter-spacing: normal !important;
}

.react-tel-input .form-control:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(163 124 181 / var(--tw-ring-opacity));
}